import React from "react";
import {Helmet} from "react-helmet";
import {BulletItem, BulletList} from '../../components/BulletList/BulletList';
import Layout, {Headline} from '../../components/layout';
import Drawer from "../../components/drawer/drawer";
import bg from "../../assets/img/2828.jpg";

const InfoSet = [
    {
        "question": "How do I sell my property fast, without anyone entering my home during the COVID-19 pandemic?",
        "answer": [
            "We have purchased homes SIGHT UNSEEN! If you do not want anyone entering your home, we will happily provide a NO OBLIGATION offer over the phone. Please give us a call at 844-242-SELL and one of our representatives will be happy to assist you."
        ]
    },
    {
        "question": "What type of properties do you purchase?",
        "answer": [
            "We purchase ALL types of properties throughout Southern California; Single Family homes, Multi Family homes, Condos, Apartment buildings, Land & Commercial are some of the properties we have recently purchased. Any condition, any price range."
        ]
    },
    {
        "question": "Do you buy properties that are tenant occupied?",
        "answer": [
            "Yes, of course! We purchase properties that are tenant occupied, even if the tenant is behind on their rent. Please call us for more details."
        ]
    },
    {
        "question": "I have inherited a home and was told I need to go through the probate process...Will Direct Buyers still purchase my home?",
        "answer": [
            "Yes, We have a lot of experience with probate cases and would be happy to assist. Please call us for more information."
        ]
    },
    {
        "question": "Am I obligated to sell my home to Direct Buyers once you make me an offer?",
        "answer": [
            "Absolutely NOT! We provide NO obligation offers. We provide fair offers to accommodate seller's with their specific needs. We are known to beat our competitors' written offers! Most important, we will close as promised or you keep our deposit!"
        ]
    },
    {
        "question": "How much does it cost me to sell my home to Direct Buyers?",
        "answer": [
            "There are NO fees! NO commissions! NO Hassles! We offer free, NO obligation offers. Additionally, if you chose to sell your home to Direct Buyers we will pay the Seller's escrow closing costs!"
        ]
    },
    {
        "question": "How soon after I accept your offer will I receive my sale proceeds?",
        "answer": [
            "Once you accept Direct Buyer’s offer, YOU choose your closing date and we open escrow! We can close in as fast as 10 days or on your schedule! (sometimes sooner)"
        ]
    },
    {
        "question": "What if my home needs major repairs?",
        "answer": [
            "We buy ANY PROPERTY in ANY CONDITION! You do NOT have to make any repairs. We buy in AS-IS CONDITION! We buy homes with delayed maintenance, a lot of cluster and you can even leave behind any belongings you no longer want to keep. We will take care of it for you!"
        ]
    },
    {
        "question": "What if I am behind on my mortgage or property taxes?",
        "answer": [
            "No problem! We are able to purchase your home in this situation. Please call us to provide us with a better picture of the situation."
        ]
    }
]

export default function FAQ() {
    return (
        <Layout
            bg={bg}
            header={"Over 30 years Experience in the Real Estate Industry!"}
            subheader={" Distressed Homes - Inherited - Probates - Code Violations  Liens - Behind on your Mortgage - Problem Tenants - Death         WE CAN HELP!"}
        >
            <Helmet>
                <title>FAQs : Direct Buyers</title>
                <meta name="description" content="You have questions. Direct Buyers has answers." />
            </Helmet>

            <div className={`container`}>
                <Headline><h1>FAQs</h1></Headline>

                {InfoSet.map((item, key) => {
                    return (
                        <Drawer q={item.question} a={item.answer} key={key} />
                    )
                })}

                <Headline><h3>REASONS TO SELL YOUR HOME:</h3></Headline>
                <BulletList>
                    <BulletItem>Inherited a Home</BulletItem>
                    <BulletItem>Divorce</BulletItem>
                    <BulletItem>Title Issues</BulletItem>
                    <BulletItem>Downsizing</BulletItem>
                    <BulletItem>Death or Probate</BulletItem>
                    <BulletItem>Job Loss</BulletItem>
                    <BulletItem>Damaged or Hoarder Home</BulletItem>
                    <BulletItem>Bad Tenants</BulletItem>
                    <BulletItem>Liens or Judgements</BulletItem>
                    <BulletItem>Code Violations</BulletItem>
                    <BulletItem>Moving Out of State</BulletItem>
                    <BulletItem>Behind on Mortgage or Taxes</BulletItem>
                    <BulletItem>Short Sale or Foreclosure</BulletItem>
                    <BulletItem>Overwhelming Repairs or Structural Issues</BulletItem>
                </BulletList>
            </div>
        </Layout>
    );
}
