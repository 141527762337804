import React, { useState } from "react";
import styled from "styled-components";
import mq from '../../styles/media-query';

const DrawerFrame = styled.div`
    & + & {
        border-top: 1px solid #ddd;
        padding-top: 1.6rem;
        margin-top: 1.6rem;
    }
    &[data-open="true"] {
        &, & + div {
            border-color: var(--blueGrey);
        }
        + & {
            margin-top: 2.4rem;
        }
    }
`;

const Question = styled.button`
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.2;
    text-align: left;
    color: var(--textOverLight);
    background: transparent;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
    ${mq.sm`
        font-size: 1.75rem;
    `}
    [data-open="true"] & {
        display: block;
        color: var(--brandPrimary);
    }
`;

const Answer = styled.div`
    display: none;
    margin: 1.6rem 0;
    
    [data-open="true"] & {
        display: block;
    }
`;

const Drawer = ({q, a}) => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    }
    return (
        <DrawerFrame data-open={drawerOpen}>
            <Question onClick={() => toggleDrawer()}>{q}</Question>
            <Answer>
                {a.map((ans, key) => {
                    return (
                        <p key={key}>{ans}</p>
                    )
                })}
                </Answer>
        </DrawerFrame>
    );
}

export default Drawer;